var swiper = new Swiper(".mySwiper", {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    loopFillGroupWithBlank: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
      },
});


dateIn = function()
{
    var data_ida = document.getElementById('data_ida').value
    document.getElementById("data_volta").min = data_ida;
    var data_volta = document.getElementById("data_volta").value

    if(data_volta < data_ida)
    {
        document.getElementById("data_volta").value = ''
    }
}

editInfo = function()
{
  $("#formLead").show(500);
  $("#infoViagem").hide("slow");
}


comparacaoProduct = function(id){

    var countProduct = document.getElementById('countProduct').value
    var listProduct = document.getElementById('listProduct').value;
    var elem = document.getElementById("card__plans"+id);
    var element = document.getElementById("img_logo"+id).value;
    var productsImg = document.getElementById('productsImg').innerHTML

    if(listProduct.includes(',' + id)){
        document.getElementById('listProduct').value = listProduct.replace(',' + id, '')
        elem.classList.remove("selected__product");
        const element = document.getElementById("img_seguradora"+id);
        element.remove();

        document.getElementById('countProduct').value = --countProduct
    }else{
        if(countProduct < 4)
        {
            document.getElementById('listProduct').value = listProduct + ',' + id
            elem.classList.add("selected__product");
            var img = '<div class="col-12 col-md-3" id="img_seguradora'+id+'"><img src="'+element+'" alt="" class="img-fluid"></img></div>';
            document.getElementById('productsImg').innerHTML = productsImg + img
            document.getElementById('countProduct').value = ++countProduct
        }
    }

    if(countProduct > 0)
    {
        $("#productComparar").show(500);
    }else{
        $("#productComparar").hide('show');
    }
}


window.onscroll = function() {myFunction()};
    var header = document.getElementById("myHeader");
    var headerMobile = document.getElementById("myHeaderMobile");

    var sticky = header.offsetTop;
    var stickyMobile = headerMobile.offsetTop;

myFunction = function() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }

    if (window.pageYOffset > stickyMobile) {
        headerMobile.classList.add("sticky");
    } else {
        headerMobile.classList.remove("sticky");
    }
}
